<template>
  <div class="select-tree" :class="{ 'min-w': label }">
    <!-- <div class="label" v-if="label">{{label}}</div> -->
    <div class="content">
      <el-input
        :label="label"
        clearable
        ref="input"
        class="search"
        v-model="filterText"
        :placeholder="placeholder"
        :width="width"
        :suffix-icon="showTree ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
        @focus="onFocus"
        @blur="onBlur"
        @clear="onClear"
      />
      <div class="hx-tree-group" v-show="showTree" ref="treeGroup">
        <div
          class="tree-group__box"
          v-show="treeData.length !== 0"
          :style="{ width: `${width}px` }"
        >
          <el-tree 
            :data="treeData"
            :props="treeProps"
            @node-click="handleNodeClick"
          />
        </div>
        <div
          v-show="treeData.length === 0"
          class="tree-group__box is-empty"
          :style="{ width: `${width}px` }"
        >暂无数据</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'deviceSelectTree',
  props: {
    // 选中项的值，支持`.sync`
    value: {
      type: [String, Number],
      required: true,
    },
    data: {
      // 对应value的节点数据
      type: Object,
      default() {
        return {}
      },
    },
    label: String, // 用于该组件作为查询条件时
    placeholder: {
      type: String,
      default: '查询所属部门',
    },
    width: {
      type: Number,
      default: 182,
    },
    treeData: {
      type: Array,
      default: () => {
        return []
      },
    },
    treeProps: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      showTree: false,
      filterText: '',
      key: 0,
    }
  },
  mounted() {
    //为阻止input框失去焦点
    this.$refs.treeGroup.onmousedown = function (e) {
      if (e && e.preventDefault) {
        // 现代浏览器阻止默认事件
        e.preventDefault()
      } else {
        // IE阻止默认事件
        window.event.returnValue = false
      }
      return false
    }
  },
  methods: {
    onFocus() {
      setTimeout(() => {
        this.showTree = true
      }, 100)
    },
    onClear(){
      this.$emit('clear')
    },
    onBlur() {
      this.showTree = false
    },
    handleNodeClick(data,node,nodeValue){
       this.filterText = data[this.treeProps.label]
       this.$emit('nodeClick', data, node, nodeValue)
       this.$refs.input.blur()
    },
  },
}
</script>

<style lang="scss" scoped>
.select-tree {
  position: relative;
  display: flex;
  .label {
    color: #333333;
    flex: 1;
    text-align: right;
    line-height: 40px;
    display: inline-block;
    margin-right: 5px;
  }
  .content {
    position: relative;
    .search {
      ::v-deep .el-input__suffix {
        line-height: 32px;
      }
    }
  }
}
</style>
<style lang="scss">
.hx-tree-group {
  &::before {
    content: "";
    border: 1px solid;
    border-color: #e4e7ed transparent transparent #e4e7ed;
    background-color: #fff;
    width: 6px;
    height: 6px;
    position: absolute;
    top: 2px;
    left: 36px;
    z-index: 2004;
    transform: rotateZ(45deg);
  }
  position: absolute;
  left: 0;
  top: 110%;
  z-index: 3000;
  color: #606266;
  .tree-group__box {
    &.is-empty {
      height: auto;
      color: #909399;
      text-align: center;
      padding: 4px 0;
      font-size: 14px;
    }
    /** 不可使用定位,会破坏button-tree组件结构 */
    // width: 182px;
    height: 204px;
    overflow-y: scroll;
    border: 1px solid #e4e7ed;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    margin: 5px 0;
    padding: 5px 0 0;
  }
}
</style>
