<template>
  <div class="batchEdit-container">
    <form-panel class="form-container" ref="formPanel" :form="form" v-bind="submitConfig" :submitUrl="submitUrl"
      submitText="保存" :submitBefore="submitBefore">
      <col2-detail :span='24'>
        <col2-block title="批量修改设施设备信息：">
          <el-form-item label="修改类型" prop="editType" :rules="[{ required: true, message: '请填写修改类型', trigger: 'blur' }]">
            <v-select v-model="form.editType" :options="editOps" @change="editTypeChange"></v-select>
          </el-form-item>
          <el-form-item v-if="form.editType === 2" label="项目范围" prop="projectArea"
            :rules="[{ required: true, message: '请填写项目范围', trigger: 'blur' }]">
            <v-select v-model="form.projectArea" :options="projectAreaOps"></v-select>
          </el-form-item>
          <el-form-item v-if="form.editType !== 2 || form.projectArea == 1" label="选择项目" prop="communityId"
            :rules="[{ required: true, message: '请填写选择项目', trigger: 'blur' }]">
            <v-select2 v-model="form.communityId" v-bind="communityParams" placeholder="查询项目"></v-select2>
          </el-form-item>
          <el-form-item v-if="form.editType === 2 || form.editType === 5 || form.editType === 6" label="选择分类"
            prop="cagetoryId">
            <!-- <v-select2 v-model="form.cagetoryId" v-bind="cagetoryParams" placeholder="查询设备分类"></v-select2> -->
            <device-select-tree :value="1" placeholder="查询设备分类" :treeData="treeData" :treeProps="defaultProps"
              @nodeClick="nodeClick" @clear="onClear" />
          </el-form-item>
          <el-form-item label="关联设备" :rules="[{ required: true, validator: tagValid, trigger: 'blur1' }]" prop="longitude">
            <chosenListPanel :list.sync="deviceList" @select="toSelectTag"></chosenListPanel>
          </el-form-item>
        </col2-block>
      </col2-detail>
      <col2-detail :span='24'>
        <col2-block title="新关联内容：">
          <div v-if="form.editType === 1">
            <el-form-item label="责任人" prop="name" :rules="[{ required: true, message: '请填写责任人', trigger: 'blur' }]">
              <v-input v-model="form.name" />
            </el-form-item>
            <el-form-item label="责任人手机号" prop="phone"
              :rules="[{ required: true, message: '请填写责任人手机号', trigger: 'blur' }, { validator: validatePhone, trigger: 'blur' }]">
              <v-input v-model="form.phone" />
            </el-form-item>
          </div>
          <div v-if="form.editType === 2">
            <el-form-item label="维保单位" prop="maintCompanyId"
              :rules="[{ required: true, message: '请填写维保单位', trigger: 'blur' }]">
              <v-select2 v-model="form.maintCompanyId" v-bind="maintCompanyParams"
                @onChange="maintCompanyChange"></v-select2>
            </el-form-item>
            <el-form-item label="单位地址">
              {{ address }}
            </el-form-item>
            <el-form-item label="联系人姓名">
              {{ userName }}
            </el-form-item>
            <el-form-item label="联系电话">
              {{ userPhone }}
            </el-form-item>
          </div>
          <div v-if="form.editType === 3">
            <el-form-item label="巡检负责人" prop="chargerUserId"
              :rules="[{ required: true, message: '请选择巡检负责人', trigger: 'blur' }]">
              <v-select2 v-model="form.chargerUserId" v-bind="chargerUserParams" :subjoin="deviceExtraParams"></v-select2>
            </el-form-item>
          </div>
          <div v-if="form.editType === 4">
            <el-form-item label="维保负责人" prop="chargerUserId"
              :rules="[{ required: true, message: '请选择维保负责人', trigger: 'blur' }]">
              <v-select2 v-model="form.chargerUserId" v-bind="chargerUserParams" :subjoin="deviceExtraParams"></v-select2>
            </el-form-item>
          </div>
          <div v-if="form.editType === 5">
            <el-form-item label="是否维保" prop="isMaint"
              :rules="[{ required: true, message: '请选择是否维保', trigger: 'change' }]">
              <v-select v-model="form.isMaint" :options="maintenanceOps" />
            </el-form-item>
            <div v-if="form.isMaint === 1">
              <el-form-item label="维保周期" prop="period"
                :rules="[{ required: true, message: '请输入维保周期', trigger: 'change' }]">
                <v-select v-model="form.period" :options="periodOps" />
              </el-form-item>
              <el-form-item label="维保提醒" prop="floatingDays"
                :rules="[{ required: true, message: '请输入维保提醒', trigger: 'change' }]">
                <v-select v-model="form.floatingDays" :options="floatingDaysOps" />
              </el-form-item>
              <el-form-item label="首次维保日期" prop="firstMaintDate"
                :rules="[{ required: true, message: '请输入首次维保日期', trigger: 'blur' }]">
                <v-datepicker v-model="form.firstMaintDate" type="date" placeholder="选择日期"></v-datepicker>
              </el-form-item>
            </div>
          </div>
          <!--这里editType === 6会出现前面两个el-form-item的undefined is required的bug -->
          <!-- 一种hack方法让el-form-item,没有任何内容显示,同时下面的margin-top:-40px覆盖掉上面的空白 -->
          <div v-if="form.editType === 6">
            <el-form-item></el-form-item>
            <el-form-item></el-form-item>
          </div>
          <!-- 如果把这个div弄到col2-block外面第一个el-form-item的样式会崩掉 -->
          <div v-if="form.editType === 6" class="label-data">
            <!-- <el-form-item label="是否是c1es"  required></el-form-item>  -->
            <el-form-item label="是否巡检" prop="isPatrol"
              :rules="[{ required: true, message: '请选择是否巡检', trigger: 'change' }]">
              <v-select v-model="form.isPatrol" :options="maintenanceOps" />
            </el-form-item>
            <div v-if="form.isPatrol === 1">
              <el-form-item label="巡检频次" required>
                <div class="content-wrapper">
                  <el-form-item prop="period" :rules="[{ required: true, message: '请输入内容' }]">
                    <v-select class="right-wrapper" v-model="form.period" :options="periodForm" :width="182" />
                  </el-form-item>
                  <el-form-item prop="periodNum">
                    <v-input-number controls v-model="form.periodNum" controls-position="right" placeholder="1" :min="1"
                      :max="99" :width="182" />
                  </el-form-item>
                  <span class="right-tip">如：“日、3”表示每日巡检3次</span>
                </div>
              </el-form-item>
              <el-form-item label="巡检间隔" required>
                <div class="content-wrapper">
                  <el-form-item prop="timespan" :rules="{ required: true, message: '内容不允许为空', trigger: 'blur' }">
                    <v-input-number controls v-model="form.timespan" class="right-wrapper" controls-position="right"
                      placeholder="1" :min="0" :max="9999999999" :width="182" />
                  </el-form-item>
                  <el-form-item prop="timespanUnit" :rules="{ required: true, message: '当前选项不允许为空', trigger: 'change' }">
                    <v-select v-model="form.timespanUnit" :options="timespanUnit" :width="182" />
                  </el-form-item>
                  <span class="right-tip">两次巡检间隔最小时间</span>
                </div>
              </el-form-item>
              <el-form-item label="提醒时间" prop="floatingDays"
                :rules="[{ required: true, message: '请输入维保提醒', trigger: 'change' }]">
                <v-select v-model="form.floatingDays" :options="floatingDaysOps" />
              </el-form-item>
              <el-form-item label="首次巡检日期" prop="firstPatrolDate"
                :rules="[{ required: true, message: '请输入首次巡检日期', trigger: 'change' }]">
                <v-datepicker v-model="form.firstPatrolDate" type="date" />
              </el-form-item>
            </div>
          </div>
          <div v-if="form.editType === 7">
            <el-form-item style="margin-left:60px;" label="是否允许相册上传图片">
              <el-switch v-model="allowAlbumUpload" :active-value="1" :inactive-value="0" active-color="#409EFF" />
            </el-form-item>
          </div>
          <div v-if="form.editType === 8">
            <el-form-item label="巡检执行人" prop="executorUserId"
              :rules="[{ required: true, message: '请选择巡检执行人', trigger: 'blur' }]">
              <v-select2 v-model="form.executorUserId" v-bind="chargerUserParams"
                :subjoin="deviceExtraParams"></v-select2>
            </el-form-item>
          </div>
          <div v-if="form.editType === 9">
            <el-form-item label="维保执行人" prop="executorUserId"
              :rules="[{ required: true, message: '请选择维保执行人', trigger: 'blur' }]">
              <v-select2 v-model="form.executorUserId" v-bind="chargerUserParams"
                :subjoin="deviceExtraParams"></v-select2>
            </el-form-item>
          </div>
        </col2-block>
      </col2-detail>
    </form-panel>
    <multi-select2 title="关联设备列表" :isShow.sync="isShow" :searchUrl="getListURL" :headers="deviceTableHeader"
      :searchParams="searchParams" :responseParams="responseParams" :responseKey="responseKey"
      :extraParams="deviceExtraParams" :backFill.sync="deviceList" :maxCount="3000">
      <template #searchSlot>
        <v-input v-model="searchParams.name" label="设备名称"></v-input>
        <v-input v-model="searchParams.serialNum" label="设备编号"></v-input>
        <v-select label="状态" v-model="searchParams.deviceStatus" :options="deviceStatusOps"></v-select>
        <v-input v-model="searchParams.address" label="安装位置"></v-input>
        <v-input v-model="searchParams.patrolUserName" label="巡检责任人"></v-input>
        <v-input v-model="searchParams.maintUserName" label="维保责任人"></v-input>
        <v-input v-model="searchParams.userName" label="责任人"></v-input>
        <v-select2 v-model="searchParams.maintCompanyId" v-bind="maintCompanyParams" :subjoin="maintSubjoin"
          label="维保单位"></v-select2>
      </template>
    </multi-select2>
  </div>
</template>
<script>
import { Col2Block, Col2Detail, ChosenListPanel, MultiSelect2 } from '@/components/bussiness'
import deviceSelectTree from './component/deviceSelectTree'
import {
  getListURL,
  getUsernameURL,
  getCategoryListURL,
  getMaintenanceUnitURL,
  batchUpdateChargerURL,
  batchUpdateMaintCompanyURL,
  batchUpdateMaintChargerURL,
  batchUpdatePatrolChargerURL,
  updatePatrolInspectionExecutorURL,
  updateMaintenanceExecutorURL,
  batchUpdateMaintURL,
  batchUpdatePatrolURL,
  getTreeDataURL,
  updateOthersURL
} from './api'
import {
  editOps,
  projectAreaOps,
  deviceStatusOps,
  periodOps,
  periodForm,
  timespanUnit,
  maintenanceOps
} from './map'
import { normalCommunityParams } from 'common/select2Params'
import { mobileRegular } from 'common/regular'
export default {
  components: {
    Col2Block,
    Col2Detail,
    ChosenListPanel,
    MultiSelect2,
    deviceSelectTree,
  },
  data () {
    return {
      editOps,
      getListURL,
      periodOps,
      periodForm,
      projectAreaOps,
      deviceStatusOps,
      timespanUnit,
      maintenanceOps,
      submitConfig: {
        submitContentType: 'application/x-www-form-urlencoded;charset=UTF-8'
      },
      form: {
        editType: 1,
        projectArea: 1,
        cagetoryId: '',
        communityId: '',
        id: '',
        name: '',
        phone: '',
        maintCompanyId: '',
        chargerUserId: '',//维保负责人
        period: 1, // 维保周期和巡检频次
        floatingDays: 1,//维保提醒
        firstMaintDate: '',//提醒时间
        periodNum: '',
        timespan: undefined,//巡检间隔
        timespanUnit: 1,//巡检间隔单位
        firstPatrolDate: '',//首次巡检时间
        isPatrol: undefined, // 是否巡检
        isMaint: undefined, // 是否维保
        //新增
        executorUserId: '',
        // patrolInspectionExecutor:'',//巡检执行人
        // maintenanceExecutor:'' //维保执行人
      },
      allowAlbumUpload: 1,
      cagetoryParams: {
        searchUrl: `${getCategoryListURL}?codeLevel=3`
      },
      communityParams: normalCommunityParams,
      maintCompanyParams: {
        searchUrl: getMaintenanceUnitURL,
        request: {
          text: 'content',
          value: 'id'
        },
        response: {
          text: 'content',
          value: 'id'
        }
      },
      chargerUserParams: {
        searchUrl: getUsernameURL,
        request: {
          text: 'username',
          value: 'userId'
        },
        response: {
          text: 'username',
          value: 'id'
        }
      },
      deviceList: [],
      searchParams: {
        name: '',
        serialNum: '',
        deviceStatus: undefined,
        address: '',
        userName: '',
        maintCompanyId: '',

      },
      deviceTableHeader: [
        {
          prop: 'name',
          label: '设备名称'
        },
        {
          prop: 'serialNum',
          label: '设备编号'
        },
        {
          prop: 'address',
          label: '安装位置'
        },
        {
          prop: 'deviceStatus',
          label: '状态'
        },
        {
          prop: 'patrolUserName',
          label: '巡检负责人'
        },
        {
          prop: 'maintUserName',
          label: '维保负责人'
        },
        {
          prop: 'maintCommpanyName',
          label: '维保单位'
        },
        {
          prop: 'chargerUserName',
          label: '责任人'
        },
      ],
      responseParams: {
        id: 'id',
        name: 'name'
      },
      responseKey: {
        id: 'id',
        name: 'text'
      },
      isShow: false,
      address: '',
      userName: '',
      userPhone: '',
      //
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'name',
        id: 'id',
      },
      treeLoading: false,
      deviceId: undefined,
      level: undefined,
    }
  },
  computed: {
    submitUrl () {
      let type = this.form.editType
      if (type === 1) {
        return batchUpdateChargerURL
      } else if (type === 2) {
        return batchUpdateMaintCompanyURL
      } else if (type === 3) {
        return batchUpdatePatrolChargerURL
      } else if (type === 4) {
        return batchUpdateMaintChargerURL
      } else if (type === 5) {
        return batchUpdateMaintURL
      } else if (type === 6) {
        return batchUpdatePatrolURL
      } else if (type === 7) {
        return updateOthersURL
      } else if (type === 8) {
        return updatePatrolInspectionExecutorURL
      } else if (type === 9) {
        return updateMaintenanceExecutorURL
      }
    },
    deviceExtraParams () {
      this.deviceList = [] // eslint-disable-line
      let obj = {}

      if (this.form.editType === 2 && this.form.projectArea === 1) {
        obj = {
          communityId: this.form.communityId,
          [this.level]: this.deviceId
        }
      } else if (this.form.editType === 5 || this.form.editType === 6) {
        obj = {
          communityId: this.form.communityId,
          [this.level]: this.deviceId
        }
      } else {
        obj = {
          communityId: this.form.communityId
        }
      }
      console.log('obj', obj)
      return obj
    },
    maintExtraParams () {
      return {
        categoryId: this.form.cagetoryId ? this.form.cagetoryId : ''
      }
    },
    maintSubjoin () {
      return {
        communityId: this.form.communityId
      }
    },
    floatingDaysOps () {
      let ops = []
      //维保提醒和设备巡检提醒是不一样的
      if (this.form.editType === 5) {
        if (this.form.period > 2) {
          ops = [{ text: '三天', value: 2 }]
        } else {
          ops = [{ text: '一天', value: 1 }]
        }
      } else if (this.form.editType === 6) {
        if (this.form.period === 0) {
          // 日
          ops = [{ text: '一天', value: 0 }]
        } else if (this.form.period === 1) {
          // 周
          ops = [
            { text: '一天', value: 0 },
            { text: '两天', value: 1 },
          ]
        } else if (this.form.period === 2) {
          // 半月
          ops = [
            { text: '一天', value: 0 },
            { text: '三天', value: 2 },
          ]
        } else if (this.form.period === 3) {
          // 月
          ops = [
            { text: '一天', value: 0 },
            { text: '三天', value: 2 },
            { text: '五天', value: 3 },
          ]
        } else if (this.form.period === 4) {
          // 季度
          ops = [
            { text: '一天', value: 0 },
            { text: '三天', value: 2 },
            { text: '五天', value: 3 },
            { text: '七天', value: 4 },
          ]
        } else if (this.form.period === 5) {
          // 半年
          ops = [
            { text: '一天', value: 0 },
            { text: '三天', value: 2 },
            { text: '五天', value: 3 },
            { text: '七天', value: 4 },

          ]
        }
      }
      return ops
    }
  },
  watch: {
    'form.period' (val) {
      console.log('form.period', val)
      // 维保频次5
      if (this.form.editType === 5) {
        if (val > 2) {
          this.form.floatingDays = 2
        } else {
          this.form.floatingDays = 1
        }
      } else {
        this.form.floatingDays = val
        //因为提醒周期没有5
        if (val === 5) {
          this.form.floatingDays = 4
        }
      }

    }
  },
  mounted () {
    this.getTreeData()
  },
  methods: {
    validatePhone (rule, value, callback) {
      let regExp = mobileRegular
      if (!regExp.test(value)) {
        callback(new Error('手机号码格式不正确'))
      } else {
        callback()
      }
    },
    toSelectTag () {
      this.isShow = true
    },
    tagValid (rule, value, callback) {
      if (this.deviceList.length > 0) {
        callback()
      } else {
        callback(new Error('请选择关联设备'))
      }
    },
    periodGapJudeg (period, periodNum, timespan, timespanUnit) {
      let time = periodNum * timespan;
      if (time === 0) {
        this.$alert('保存失败，巡检频次和巡检间隔设置有误，请重新输入')
        return false
      }
      if (timespanUnit === 1) {
        if (
          (period === 0 && time > 1 * 24) ||
          (period === 1 && time > 7 * 24) ||
          (period === 2 && time > 14 * 24) ||
          (period === 3 && time > 28 * 24) ||
          (period === 4 && time > 89 * 24) ||
          (period === 5 && time > 179 * 24)
        ) {
          this.$alert('保存失败，巡检频次和巡检间隔设置有误，请重新输入')
          return false
        }
      } else if (timespanUnit === 2) {
        if (
          (period === 0 && time !== 1) ||
          (period === 1 && time > 7) ||
          (period === 2 && time > 14) ||
          (period === 3 && time > 28) ||
          (period === 4 && time > 89) ||
          (period === 5 && time > 179)
        ) {
          this.$alert('保存失败，巡检频次和巡检间隔设置有误，请重新输入')
          return false
        }
      }
      return true
    },
    submitBefore (data) {
      if (data.editType === 1) {
        delete data.chargerUserId
        delete data.firstMaintDate
        delete data.periodNum
        delete data.timespan
        delete data.firstPatrolDate
        delete data.isPatrol
        delete data.isMaint
        delete data.id
        delete data.executorUserId
        data.deviceIds = JSON.stringify(this.deviceList.map(val => val.id))
      } else if (data.editType === 2) {
        delete data.chargerUserId
        delete data.executorUserId
        data.deviceIds = JSON.stringify(this.deviceList.map(val => val.id))
      } else if (data.editType === 3) {
        data.ids = this.deviceList.map(val => val.id)
      } else if (data.editType === 4) {
        data.ids = this.deviceList.map(val => val.id)
      } else if (data.editType === 5) {
        data.firstPatrolDate = undefined
        data.periodNum = undefined
        data.timespanUnit = undefined
        // data.isMaint = 1
        data.isPatrol = undefined
        data.deviceIds = this.deviceList.map(val => val.id)
      } else if (data.editType === 6) {
        data.firstMaintDate = undefined
        // data.isPatrol = 1
        data.isMaint = undefined
        data.deviceIds = this.deviceList.map(val => val.id)
        if (!this.periodGapJudeg(data.period, data.periodNum, data.timespan, data.timespanUnit)) {
          return false
        }
      } else if (data.editType === 7) {
        delete data.chargerUserId
        delete data.firstMaintDate
        delete data.periodNum
        delete data.timespan
        delete data.firstPatrolDate
        delete data.isPatrol
        delete data.isMaint
        delete data.id
        delete data.period
        delete data.floatingDays
        delete data.timespanUnit
        delete data.executorUserId
        data.allowAlbumUpload = this.allowAlbumUpload
        data.deviceIds = this.deviceList.map(val => val.id)
      } else if (data.editType === 8) {
        data.ids = this.deviceList.map(val => val.id)
      } else if (data.editType === 9) {
        data.ids = this.deviceList.map(val => val.id)
      }
      if (data.editType !== 1) {
        delete data.name
        delete data.phone
      }
      if (data.editType !== 2) {
        delete data.maintCompanyId
      }
      delete data.cagetoryId
      delete data.communityId
      delete data.editType
      delete data.projectArea
      // return false;
      return true
    },
    maintCompanyChange (val) {
      if (val) {
        this.address = val.address
        this.userName = val.contact
        this.userPhone = val.phone
      }
    },
    editTypeChange (val) {
      if (val === 3 || val === 4 || val === 5 || val === 6 || val === 7 || val === 8 || val === 9) {
        this.submitConfig.submitContentType = 'application/json'
      } else {
        this.submitConfig.submitContentType = 'application/x-www-form-urlencoded;charset=UTF-8'
      }
      this.$refs.formPanel.clearValidate()
    },
    /**
     * 获取treeData数据
     */
    async getTreeData () {
      this.treeLoading = true;
      let res = await this.$axios.get(getTreeDataURL);
      this.treeLoading = false;
      if (res.status = 100) {
        this.treeData = res.data;
      }
    },
    getLevel (number) {
      let level = '';
      switch (number) {
        case 1:
          level = 'firstLevel'
          break;
        case 2:
          level = 'secondLevel'
          break;
        case 3:
          level = 'thirdLevel'
          break;
        default:
          break;
      }
      return level
    },
    nodeClick (data, Node) {
      this.deviceId = data['id']
      this.level = this.getLevel(Node.level)
    },
    //foucu之后需要清空level和deviceId,因为
    onClear () {
      this.level = undefined;
      this.deviceId = undefined
    }
  }
}
</script>
<style lang="scss" scoped>
.content-wrapper {
  display: flex;
  justify-content: flex-start;

  .right-wrapper {
    margin-right: 30px;
  }

  .right-tip {
    margin-left: 15px;
  }
}

.label-data {
  // hack方法把上面的给覆盖了
  margin-top: -40px;

  .el-form-item {
    ::v-deep .el-form-item__label {
      font-weight: bold;
      color: #333;
    }
  }

}
</style>
