var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "batchEdit-container" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            staticClass: "form-container",
            attrs: {
              form: _vm.form,
              submitUrl: _vm.submitUrl,
              submitText: "保存",
              submitBefore: _vm.submitBefore,
            },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            { attrs: { span: 24 } },
            [
              _c(
                "col2-block",
                { attrs: { title: "批量修改设施设备信息：" } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "修改类型",
                        prop: "editType",
                        rules: [
                          {
                            required: true,
                            message: "请填写修改类型",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: { options: _vm.editOps },
                        on: { change: _vm.editTypeChange },
                        model: {
                          value: _vm.form.editType,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "editType", $$v)
                          },
                          expression: "form.editType",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.form.editType === 2
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "项目范围",
                            prop: "projectArea",
                            rules: [
                              {
                                required: true,
                                message: "请填写项目范围",
                                trigger: "blur",
                              },
                            ],
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: { options: _vm.projectAreaOps },
                            model: {
                              value: _vm.form.projectArea,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "projectArea", $$v)
                              },
                              expression: "form.projectArea",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.form.editType !== 2 || _vm.form.projectArea == 1
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "选择项目",
                            prop: "communityId",
                            rules: [
                              {
                                required: true,
                                message: "请填写选择项目",
                                trigger: "blur",
                              },
                            ],
                          },
                        },
                        [
                          _c(
                            "v-select2",
                            _vm._b(
                              {
                                attrs: { placeholder: "查询项目" },
                                model: {
                                  value: _vm.form.communityId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "communityId", $$v)
                                  },
                                  expression: "form.communityId",
                                },
                              },
                              "v-select2",
                              _vm.communityParams,
                              false
                            )
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.form.editType === 2 ||
                  _vm.form.editType === 5 ||
                  _vm.form.editType === 6
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "选择分类", prop: "cagetoryId" } },
                        [
                          _c("device-select-tree", {
                            attrs: {
                              value: 1,
                              placeholder: "查询设备分类",
                              treeData: _vm.treeData,
                              treeProps: _vm.defaultProps,
                            },
                            on: {
                              nodeClick: _vm.nodeClick,
                              clear: _vm.onClear,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "关联设备",
                        rules: [
                          {
                            required: true,
                            validator: _vm.tagValid,
                            trigger: "blur1",
                          },
                        ],
                        prop: "longitude",
                      },
                    },
                    [
                      _c("chosenListPanel", {
                        attrs: { list: _vm.deviceList },
                        on: {
                          "update:list": function ($event) {
                            _vm.deviceList = $event
                          },
                          select: _vm.toSelectTag,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "col2-detail",
            { attrs: { span: 24 } },
            [
              _c("col2-block", { attrs: { title: "新关联内容：" } }, [
                _vm.form.editType === 1
                  ? _c(
                      "div",
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "责任人",
                              prop: "name",
                              rules: [
                                {
                                  required: true,
                                  message: "请填写责任人",
                                  trigger: "blur",
                                },
                              ],
                            },
                          },
                          [
                            _c("v-input", {
                              model: {
                                value: _vm.form.name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "name", $$v)
                                },
                                expression: "form.name",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "责任人手机号",
                              prop: "phone",
                              rules: [
                                {
                                  required: true,
                                  message: "请填写责任人手机号",
                                  trigger: "blur",
                                },
                                {
                                  validator: _vm.validatePhone,
                                  trigger: "blur",
                                },
                              ],
                            },
                          },
                          [
                            _c("v-input", {
                              model: {
                                value: _vm.form.phone,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "phone", $$v)
                                },
                                expression: "form.phone",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.form.editType === 2
                  ? _c(
                      "div",
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "维保单位",
                              prop: "maintCompanyId",
                              rules: [
                                {
                                  required: true,
                                  message: "请填写维保单位",
                                  trigger: "blur",
                                },
                              ],
                            },
                          },
                          [
                            _c(
                              "v-select2",
                              _vm._b(
                                {
                                  on: { onChange: _vm.maintCompanyChange },
                                  model: {
                                    value: _vm.form.maintCompanyId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "maintCompanyId", $$v)
                                    },
                                    expression: "form.maintCompanyId",
                                  },
                                },
                                "v-select2",
                                _vm.maintCompanyParams,
                                false
                              )
                            ),
                          ],
                          1
                        ),
                        _c("el-form-item", { attrs: { label: "单位地址" } }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.address) +
                              "\n          "
                          ),
                        ]),
                        _c("el-form-item", { attrs: { label: "联系人姓名" } }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.userName) +
                              "\n          "
                          ),
                        ]),
                        _c("el-form-item", { attrs: { label: "联系电话" } }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.userPhone) +
                              "\n          "
                          ),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.form.editType === 3
                  ? _c(
                      "div",
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "巡检负责人",
                              prop: "chargerUserId",
                              rules: [
                                {
                                  required: true,
                                  message: "请选择巡检负责人",
                                  trigger: "blur",
                                },
                              ],
                            },
                          },
                          [
                            _c(
                              "v-select2",
                              _vm._b(
                                {
                                  attrs: { subjoin: _vm.deviceExtraParams },
                                  model: {
                                    value: _vm.form.chargerUserId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "chargerUserId", $$v)
                                    },
                                    expression: "form.chargerUserId",
                                  },
                                },
                                "v-select2",
                                _vm.chargerUserParams,
                                false
                              )
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.form.editType === 4
                  ? _c(
                      "div",
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "维保负责人",
                              prop: "chargerUserId",
                              rules: [
                                {
                                  required: true,
                                  message: "请选择维保负责人",
                                  trigger: "blur",
                                },
                              ],
                            },
                          },
                          [
                            _c(
                              "v-select2",
                              _vm._b(
                                {
                                  attrs: { subjoin: _vm.deviceExtraParams },
                                  model: {
                                    value: _vm.form.chargerUserId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "chargerUserId", $$v)
                                    },
                                    expression: "form.chargerUserId",
                                  },
                                },
                                "v-select2",
                                _vm.chargerUserParams,
                                false
                              )
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.form.editType === 5
                  ? _c(
                      "div",
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "是否维保",
                              prop: "isMaint",
                              rules: [
                                {
                                  required: true,
                                  message: "请选择是否维保",
                                  trigger: "change",
                                },
                              ],
                            },
                          },
                          [
                            _c("v-select", {
                              attrs: { options: _vm.maintenanceOps },
                              model: {
                                value: _vm.form.isMaint,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "isMaint", $$v)
                                },
                                expression: "form.isMaint",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm.form.isMaint === 1
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "维保周期",
                                      prop: "period",
                                      rules: [
                                        {
                                          required: true,
                                          message: "请输入维保周期",
                                          trigger: "change",
                                        },
                                      ],
                                    },
                                  },
                                  [
                                    _c("v-select", {
                                      attrs: { options: _vm.periodOps },
                                      model: {
                                        value: _vm.form.period,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "period", $$v)
                                        },
                                        expression: "form.period",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "维保提醒",
                                      prop: "floatingDays",
                                      rules: [
                                        {
                                          required: true,
                                          message: "请输入维保提醒",
                                          trigger: "change",
                                        },
                                      ],
                                    },
                                  },
                                  [
                                    _c("v-select", {
                                      attrs: { options: _vm.floatingDaysOps },
                                      model: {
                                        value: _vm.form.floatingDays,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "floatingDays",
                                            $$v
                                          )
                                        },
                                        expression: "form.floatingDays",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "首次维保日期",
                                      prop: "firstMaintDate",
                                      rules: [
                                        {
                                          required: true,
                                          message: "请输入首次维保日期",
                                          trigger: "blur",
                                        },
                                      ],
                                    },
                                  },
                                  [
                                    _c("v-datepicker", {
                                      attrs: {
                                        type: "date",
                                        placeholder: "选择日期",
                                      },
                                      model: {
                                        value: _vm.form.firstMaintDate,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "firstMaintDate",
                                            $$v
                                          )
                                        },
                                        expression: "form.firstMaintDate",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.form.editType === 6
                  ? _c("div", [_c("el-form-item"), _c("el-form-item")], 1)
                  : _vm._e(),
                _vm.form.editType === 6
                  ? _c(
                      "div",
                      { staticClass: "label-data" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "是否巡检",
                              prop: "isPatrol",
                              rules: [
                                {
                                  required: true,
                                  message: "请选择是否巡检",
                                  trigger: "change",
                                },
                              ],
                            },
                          },
                          [
                            _c("v-select", {
                              attrs: { options: _vm.maintenanceOps },
                              model: {
                                value: _vm.form.isPatrol,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "isPatrol", $$v)
                                },
                                expression: "form.isPatrol",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm.form.isPatrol === 1
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: { label: "巡检频次", required: "" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "content-wrapper" },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop: "period",
                                              rules: [
                                                {
                                                  required: true,
                                                  message: "请输入内容",
                                                },
                                              ],
                                            },
                                          },
                                          [
                                            _c("v-select", {
                                              staticClass: "right-wrapper",
                                              attrs: {
                                                options: _vm.periodForm,
                                                width: 182,
                                              },
                                              model: {
                                                value: _vm.form.period,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "period",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.period",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-form-item",
                                          { attrs: { prop: "periodNum" } },
                                          [
                                            _c("v-input-number", {
                                              attrs: {
                                                controls: "",
                                                "controls-position": "right",
                                                placeholder: "1",
                                                min: 1,
                                                max: 99,
                                                width: 182,
                                              },
                                              model: {
                                                value: _vm.form.periodNum,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "periodNum",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.periodNum",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "right-tip" },
                                          [_vm._v("如：“日、3”表示每日巡检3次")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: { label: "巡检间隔", required: "" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "content-wrapper" },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop: "timespan",
                                              rules: {
                                                required: true,
                                                message: "内容不允许为空",
                                                trigger: "blur",
                                              },
                                            },
                                          },
                                          [
                                            _c("v-input-number", {
                                              staticClass: "right-wrapper",
                                              attrs: {
                                                controls: "",
                                                "controls-position": "right",
                                                placeholder: "1",
                                                min: 0,
                                                max: 9999999999,
                                                width: 182,
                                              },
                                              model: {
                                                value: _vm.form.timespan,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "timespan",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.timespan",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop: "timespanUnit",
                                              rules: {
                                                required: true,
                                                message: "当前选项不允许为空",
                                                trigger: "change",
                                              },
                                            },
                                          },
                                          [
                                            _c("v-select", {
                                              attrs: {
                                                options: _vm.timespanUnit,
                                                width: 182,
                                              },
                                              model: {
                                                value: _vm.form.timespanUnit,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "timespanUnit",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.timespanUnit",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "right-tip" },
                                          [_vm._v("两次巡检间隔最小时间")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "提醒时间",
                                      prop: "floatingDays",
                                      rules: [
                                        {
                                          required: true,
                                          message: "请输入维保提醒",
                                          trigger: "change",
                                        },
                                      ],
                                    },
                                  },
                                  [
                                    _c("v-select", {
                                      attrs: { options: _vm.floatingDaysOps },
                                      model: {
                                        value: _vm.form.floatingDays,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "floatingDays",
                                            $$v
                                          )
                                        },
                                        expression: "form.floatingDays",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "首次巡检日期",
                                      prop: "firstPatrolDate",
                                      rules: [
                                        {
                                          required: true,
                                          message: "请输入首次巡检日期",
                                          trigger: "change",
                                        },
                                      ],
                                    },
                                  },
                                  [
                                    _c("v-datepicker", {
                                      attrs: { type: "date" },
                                      model: {
                                        value: _vm.form.firstPatrolDate,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "firstPatrolDate",
                                            $$v
                                          )
                                        },
                                        expression: "form.firstPatrolDate",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.form.editType === 7
                  ? _c(
                      "div",
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-left": "60px" },
                            attrs: { label: "是否允许相册上传图片" },
                          },
                          [
                            _c("el-switch", {
                              attrs: {
                                "active-value": 1,
                                "inactive-value": 0,
                                "active-color": "#409EFF",
                              },
                              model: {
                                value: _vm.allowAlbumUpload,
                                callback: function ($$v) {
                                  _vm.allowAlbumUpload = $$v
                                },
                                expression: "allowAlbumUpload",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.form.editType === 8
                  ? _c(
                      "div",
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "巡检执行人",
                              prop: "executorUserId",
                              rules: [
                                {
                                  required: true,
                                  message: "请选择巡检执行人",
                                  trigger: "blur",
                                },
                              ],
                            },
                          },
                          [
                            _c(
                              "v-select2",
                              _vm._b(
                                {
                                  attrs: { subjoin: _vm.deviceExtraParams },
                                  model: {
                                    value: _vm.form.executorUserId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "executorUserId", $$v)
                                    },
                                    expression: "form.executorUserId",
                                  },
                                },
                                "v-select2",
                                _vm.chargerUserParams,
                                false
                              )
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.form.editType === 9
                  ? _c(
                      "div",
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "维保执行人",
                              prop: "executorUserId",
                              rules: [
                                {
                                  required: true,
                                  message: "请选择维保执行人",
                                  trigger: "blur",
                                },
                              ],
                            },
                          },
                          [
                            _c(
                              "v-select2",
                              _vm._b(
                                {
                                  attrs: { subjoin: _vm.deviceExtraParams },
                                  model: {
                                    value: _vm.form.executorUserId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "executorUserId", $$v)
                                    },
                                    expression: "form.executorUserId",
                                  },
                                },
                                "v-select2",
                                _vm.chargerUserParams,
                                false
                              )
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("multi-select2", {
        attrs: {
          title: "关联设备列表",
          isShow: _vm.isShow,
          searchUrl: _vm.getListURL,
          headers: _vm.deviceTableHeader,
          searchParams: _vm.searchParams,
          responseParams: _vm.responseParams,
          responseKey: _vm.responseKey,
          extraParams: _vm.deviceExtraParams,
          backFill: _vm.deviceList,
          maxCount: 3000,
        },
        on: {
          "update:isShow": function ($event) {
            _vm.isShow = $event
          },
          "update:is-show": function ($event) {
            _vm.isShow = $event
          },
          "update:backFill": function ($event) {
            _vm.deviceList = $event
          },
          "update:back-fill": function ($event) {
            _vm.deviceList = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-input", {
                  attrs: { label: "设备名称" },
                  model: {
                    value: _vm.searchParams.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "name", $$v)
                    },
                    expression: "searchParams.name",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "设备编号" },
                  model: {
                    value: _vm.searchParams.serialNum,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "serialNum", $$v)
                    },
                    expression: "searchParams.serialNum",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "状态", options: _vm.deviceStatusOps },
                  model: {
                    value: _vm.searchParams.deviceStatus,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "deviceStatus", $$v)
                    },
                    expression: "searchParams.deviceStatus",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "安装位置" },
                  model: {
                    value: _vm.searchParams.address,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "address", $$v)
                    },
                    expression: "searchParams.address",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "巡检责任人" },
                  model: {
                    value: _vm.searchParams.patrolUserName,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "patrolUserName", $$v)
                    },
                    expression: "searchParams.patrolUserName",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "维保责任人" },
                  model: {
                    value: _vm.searchParams.maintUserName,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "maintUserName", $$v)
                    },
                    expression: "searchParams.maintUserName",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "责任人" },
                  model: {
                    value: _vm.searchParams.userName,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "userName", $$v)
                    },
                    expression: "searchParams.userName",
                  },
                }),
                _c(
                  "v-select2",
                  _vm._b(
                    {
                      attrs: { subjoin: _vm.maintSubjoin, label: "维保单位" },
                      model: {
                        value: _vm.searchParams.maintCompanyId,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "maintCompanyId", $$v)
                        },
                        expression: "searchParams.maintCompanyId",
                      },
                    },
                    "v-select2",
                    _vm.maintCompanyParams,
                    false
                  )
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }