var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "select-tree", class: { "min-w": _vm.label } },
    [
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("el-input", {
            ref: "input",
            staticClass: "search",
            attrs: {
              label: _vm.label,
              clearable: "",
              placeholder: _vm.placeholder,
              width: _vm.width,
              "suffix-icon": _vm.showTree
                ? "el-icon-arrow-up"
                : "el-icon-arrow-down",
            },
            on: { focus: _vm.onFocus, blur: _vm.onBlur, clear: _vm.onClear },
            model: {
              value: _vm.filterText,
              callback: function ($$v) {
                _vm.filterText = $$v
              },
              expression: "filterText",
            },
          }),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showTree,
                  expression: "showTree",
                },
              ],
              ref: "treeGroup",
              staticClass: "hx-tree-group",
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.treeData.length !== 0,
                      expression: "treeData.length !== 0",
                    },
                  ],
                  staticClass: "tree-group__box",
                  style: { width: `${_vm.width}px` },
                },
                [
                  _c("el-tree", {
                    attrs: { data: _vm.treeData, props: _vm.treeProps },
                    on: { "node-click": _vm.handleNodeClick },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.treeData.length === 0,
                      expression: "treeData.length === 0",
                    },
                  ],
                  staticClass: "tree-group__box is-empty",
                  style: { width: `${_vm.width}px` },
                },
                [_vm._v("暂无数据")]
              ),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }